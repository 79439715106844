<template>
  <v-dialog v-model="form.show" persistent max-width="500px">
    <v-card>
      <v-card-title class="text-h5">Комментарий на {{ form.item_edit.id }}</v-card-title>
      <v-card-text class="justify-center">
        <v-container fluid>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="6">
              <v-text-field v-model="form.item_edit.comment_admin"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="formClose">Cancel</v-btn>
        <v-btn color="blue darken-1" :loading="loading" text @click="formOK">OK</v-btn>
        <!--          <v-spacer></v-spacer>-->
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
// import Vue from 'vue';
/*
входящая переменная 'value'
и событие отправки 'input' в $emit их названия менять НЕЛЬЗЯ
т.к. только они работают с v-model в родительском компоненте
 */
export default {
  props: ['form'],
  // props: { form: { 'type':Object} },
  data: function () {
    return {
      loading: false,
      // item_edit: {
      //     comment_admin: this.item.item_edit.comment_admin,
      // },
      // local: this.form ,
      // local: JSON.parse(JSON.stringify(this.form)) ,
      // local: Vue.util.extend({}, this.form) ,
      // localtest: this.test,
    }
  },
  methods: {
    formOK() {
      // window.console.log('formBalanceOK', this.editedItem)
      this.loading = true;
      this.$axios.post(this.$url.finance_project_operations, {
        id: this.form.item_edit.id,
        comment_admin: this.form.item_edit.comment_admin
      })
          .then(response => {
            this.$emit('saved', response.data)
            this.formClose()
          })
          .catch(error => {
            this.$bus.emit('snackbar', {"error": error});
            return Promise.reject(error);
          })
          .finally(() => {
            this.loading = false;
          })
    },
    formClose() {
      this.form.show = false
    },
  }
}
</script>

<style scoped>

</style>